import React, { useState } from "react";
import axios from "axios";
import "semantic-ui-css/semantic.min.css";
import { Dropdown, Button, Container, Form, Loader } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DATA from './data.json';
import Table from "./table";

const PokerhubStats = () => {
    const [type, setType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const typeOptions = [
        { key: 1, text: "Ежедневный", value: 1 },
        { key: 2, text: "Еженедельный", value: 2 },
        { key: 3, text: "Месячный", value: 3 },
        { key: 4, text: "Квартальный", value: 4 },
        { key: 5, text: "Годовой", value: 5 },
    ];

    // Функция для форматирования даты в формат YYYY-MM-DD
    const formatDate = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяц от 0 до 11, добавляем 1
        const day = String(date.getDate()).padStart(2, "0"); // Дни с ведущим нулем, если нужно
        return `${year}-${month}-${day}`;
    };

    const handleSearch = async () => {
        if (!type || !startDate || !endDate) {
            alert("Пожалуйста, заполните все поля.");
            return;
        }

        setLoading(true);
        try {
            // Форматируем даты в формат YYYY-MM-DD
            const startFormatted = formatDate(startDate);
            const endFormatted = formatDate(endDate);

            const response = await axios.get(
                `https://telegram.firestorm.team/phpanel/list-files?start=${startFormatted}&end=${endFormatted}&type=${type}`
            );
            setData(response.data);
        } catch (error) {
            console.error("Ошибка загрузки данных", error);
            alert("Не удалось загрузить данные. Пожалуйста, попробуйте снова.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container style={{ backgroundColor: "#2A2A32", color: "#FFFFFF", padding: "20px", borderRadius: "5px", width: '100%' }}>
            <Form>
                {/* Используем flexbox для размещения всех элементов на одной строке */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
                        <label style={{ color: "#FFFFFF", marginBottom: '5px' }}>Тип</label>
                        <Dropdown
                            placeholder="Выберите тип"
                            fluid
                            selection
                            options={typeOptions}
                            onChange={(e, { value }) => setType(value)}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '32%', zIndex: 15000 }}>
                        <label style={{ color: "#FFFFFF", marginBottom: '5px' }}>Дата от</label>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="ui input"
                            style={{ width: "100%" }}
                            placeholderText="Выберите дату"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '32%', zIndex: 15000 }}>
                        <label style={{ color: "#FFFFFF", marginBottom: '5px' }}>Дата до</label>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="ui input"
                            style={{ width: "100%" }}
                            placeholderText="Выберите дату"
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button onClick={handleSearch} color="blue" style={{ width: '200px' }}>Поиск</Button>
                </div>
            </Form>

            {loading && <Loader active inline="centered" style={{ marginTop: "20px" }}>Загрузка...</Loader>}

            {data && (
                <div style={{ marginTop: "20px", color: "#FFFFFF", width: "100%", overflowX: "auto" }}>
                    <Table files={data.files} general={data.general} users={data.users}/>
                </div>
            )}
        </Container>
    );
};

export default PokerhubStats;
