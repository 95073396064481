// import React from 'react';

// const Index = () => {
//     return (
//         <div style={{ width: '100%', height: '100%' }}>
//             <iframe
//                 src="https://telegram.firestorm.team/users_data"
//                 title="UserData"
//                 width="100%"
//                 height="800px"
//                 style={{ border: 'none' }}
//             />
//         </div>
//     );
// };

// export default Index;


import React, { useEffect } from 'react';



const Index = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
        function showLoadingModal(message = "Загрузка данных...", isError = false) {
            document.getElementById('loadingOverlay').style.display = 'block';
            document.getElementById('loadingModal').style.display = 'block';
            const loadingSpinner = document.getElementById('loadingSpinner');
            const loadingMessage = document.getElementById('loadingMessage');
            const errorMessage = document.getElementById('errorMessage');
            const loadingButtons = document.getElementById('loadingButtons');

            if (isError) {
                loadingSpinner.style.display = 'none';
                loadingMessage.style.display = 'none';
                errorMessage.style.display = 'block';
                errorMessage.textContent = message;
                loadingButtons.style.display = 'block';
            } else {
                loadingSpinner.style.display = 'inline-block';
                loadingMessage.style.display = 'block';
                loadingMessage.textContent = message;
                errorMessage.style.display = 'none';
                loadingButtons.style.display = 'none';
            }
        }

        // Функция скрытия модального окна загрузки
        function hideLoadingModal() {
            document.getElementById('loadingOverlay').style.display = 'none';
            document.getElementById('loadingModal').style.display = 'none';
        }

        // Обработчики для кнопок внутри модального окна
        document.getElementById('retryButton').addEventListener('click', async function() {
            showLoadingModal(); // Показать спиннер без ошибки
            await applyFilter(); // Повторить попытку
        });

        document.getElementById('changeFilterButton').addEventListener('click', () => hideLoadingModal());


    async function applyFilter() {
        showLoadingModal();
        try {
            const form = document.querySelector('#filterForm');
            const formData = new FormData(form);

            const filterData = {};

            const isBlockedValues = [];
            if (formData.get('is_blocked') === '1') {
                isBlockedValues.push(true); 
            }
            if (formData.get('is_not_blocked') === '0') {
                isBlockedValues.push(false);
            }
            if (isBlockedValues.length) {
                filterData['is_blocked'] = isBlockedValues;
            }

            // Обработка других элементов формы, включая дата-пикеры и выпадающие списки
            formData.forEach((value, key) => {
                if (key.endsWith('[]')) {
                    // Обработка списков с множественным выбором (dropdowns)
                    let trueKey = key.slice(0, -2);
                    if (!filterData[trueKey]) {
                        filterData[trueKey] = [];
                    }
                    filterData[trueKey].push(value);
                } else {
                    // Обработка одиночных данных и чекбоксов
                    if (key == 'date_start' || key == 'date_end' || 
                        key == 'ph_reg_start' || key == 'ph_reg_end' || 
                        key == 'ph_last_activity_start' || key == 'ph_last_activity_end') {
                        filterData[key] = value || null; // Введение null для пустых дат
                    } else if (formData.get(key) !== '' && !(key in filterData)) {
                        // Убедимся, что данные еще не существуют
                        filterData[key] = value;
                    }
                }
            });

            // const response = await fetch(\`http://localhost:3095/api/analytics/get_data\`, {
            const response = await fetch(\`https://telegram.firestorm.team/api/analytics/get_data\`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filterData),
            });

            if (response.ok) {
                const data = await response.json();
                const dateRangeText = $('#date-range-text');
                // ставим текст диапазона выборки
                dateRangeText.text(data.date_range);
                populateTable(data);
                hideLoadingModal();
            }
        } catch (error) {
            console.error('Ошибка при загрузке данных: ', error);
            showLoadingModal('Произошла ошибка при загрузке данных. Повторить попытку?', true);
        }
    }

    function populateTable(data) {
        const dataUsers = data['users'] || [];
        const dataGeneral = data['general'] || [];

        const tableUsers = document.querySelector('#dataBodyUsers');
        const tableGeneral = document.querySelector('#dataBodyGeneral');
        const tableHead = document.querySelector('.table thead tr');

        tableUsers.innerHTML = '';
        tableGeneral.innerHTML = '';

        dataUsers.forEach((row, index) => {
            const tr = document.createElement('tr');
            const utmToHtml = (utm) => {
                return Object.entries(utm || {})
                    .map(([key, value]) => \`<div>\${key}: \${value}</div>\`)
                    .join('');
            };

            const events = row.events || {};
            tr.innerHTML = \`
                <td>\${index + 1}</td>
                <td>\${row.id}</td>
                <td>\${row.username}</td>
                <td>\${row.registration}</td>
                <td>\${utmToHtml(row.utm)}</td>
                <td>\${events.course_registration_button_click || ''}</td>
                <td>\${row.status !== null ? row.status : ''}</td>        
                <td>\${events.course_registration_button_click || ''}</td>
                <td>\${events.course_registration || ''}</td>
                <td>\${events.send_msg || ''}</td>
                <td>\${row.label || ''}</td>\`;
            tableUsers.appendChild(tr);
        });

        // Удаляем старые UTM столбцы перед добавлением новых
        while (tableHead.cells.length > 2) {
            tableHead.deleteCell(-1);
        }

        const uniqueUtms = new Set();
        dataGeneral.forEach(row => {
            if (row.utm) {
                Object.keys(row.utm).forEach(utm => uniqueUtms.add(utm));
            }
        });

        uniqueUtms.forEach(utm => {
            const th = document.createElement('th');
            th.textContent = utm;
            tableHead.appendChild(th);
        });

        dataGeneral.forEach(row => {
            const tr = document.createElement('tr');
            tr.innerHTML = \`
                <td>\${row.label}</td>
                <td>\${row.count}</td>
            \`;

            uniqueUtms.forEach(utm => {
                const td = document.createElement('td');
                td.textContent = (row.utm && row.utm[utm]) ? row.utm[utm] : 0;
                tr.appendChild(td);
            });

            tableGeneral.appendChild(tr);
        });
    }

        // для открытия выпадающих списков фильтров UTM
        document.addEventListener('DOMContentLoaded', function() {
            document.body.addEventListener('click', function(event) {
                const target = event.target;
                const dropdownMenus = document.querySelectorAll('.dropdown-menu');

                // Закрываем все dropdown, если клик был сделан вне dropdown
                const isInsideDropdown = (element) => {
                    for (let dropdownMenu of dropdownMenus) {
                        if (element === dropdownMenu || dropdownMenu.contains(element)) {
                            return true;
                        }
                    }
                    return false;
                };

                if (!isInsideDropdown(target)) {
                    dropdownMenus.forEach(menu => {
                        menu.style.display = 'none';
                    });
                } else {
                    if (target.classList.contains('form-check-input')) {
                        // Если клик был сделан на чекбоксе внутри dropdown, не закрываем dropdown
                        event.stopPropagation();
                    }
                }

                // Переключаем отображение текущего dropdown
                if (target.classList.contains('btn-secondary')) {
                    const dropdownMenu = target.nextElementSibling;
                    dropdownMenu.style.display = dropdownMenu.style.display === 'block' ? 'none' : 'block';
                }
            });
        });



        // Функция для переключения вкладок
        function switchTab(tabId) {
            // Скрыть все вкладки
            const tabs = document.querySelectorAll('.tab-pane');
            tabs.forEach(tab => {
                tab.classList.remove('show', 'active');
            });

            // Показать выбранную вкладку
            const selectedTab = document.getElementById(tabId);
            selectedTab.classList.add('show', 'active');

            // Применить активный класс к выбранной вкладке и убрать его с остальных
            const tabButtons = document.querySelectorAll('.nav-link');
            tabButtons.forEach(tabButton => {
                tabButton.classList.remove('active');
            });
            document.getElementById(tabId + '-tab').classList.add('active');
        }

        // Обработчики событий для вкладок
        document.getElementById('users-tab').addEventListener('click', function() {
            switchTab('users');
        });

        document.getElementById('general-tab').addEventListener('click', function() {
            switchTab('general');
        });
        
        document.addEventListener('DOMContentLoaded', async function() {
            await applyFilter();
        });

        applyFilter();
        `;
        
        document.head.appendChild(script);
        

    }, []);






















































   const fullHtml = `
    <!DOCTYPE html>
<html lang="ru">
<head>
    <meta charset="UTF-8">
    <title>Аналитика</title>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
    <style>
        body {
            background-color: #2A2A32;
            color: #ffffff;
        }

        table {
            width: 100% !important;
            table-layout: fixed; 
        }

        td {
             padding: 10px;
             border: 1px solid #495057;
        }

        .dropdown {
            position: relative;
            display: inline-block;
        }

        /* Стили для содержимого выпадающего списка */
        .dropdown-menu {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            max-height: 500px;
            overflow-y: auto;
        }

        /* Стили для элементов с чекбоксами внутри выпадающего меню */
        .dropdown-menu label {
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }

        .btn-secondary {
            margin-right: 10px;
        }

        .table, .table th, .table td {
            color: #FFFFFF;
            white-space: pre-line;
        }

        .form-group input[type=date] {
            margin-bottom: 10px;
        }

        .dropdown,
        .form-check {
            vertical-align: middle;
        }

        .separator {
            text-align: center;
        }

        .separator hr {
            margin: 0;
            padding: 0;
            border-color: white;
        }

        .filter-panel {
            border-radius: 25px;
            background-color: #22222a; 
            padding: 15px; 
            margin-top: 15px; 
            margin-bottom: 15px; 
        }

        .text-ellipsis:hover {
            cursor: pointer;
        }
        .spinner-border {
            width: 3rem;
            height: 3rem;
        }

        .text-pointer:hover {
            cursor: pointer;
        }

        .nav-link:hover{
            cursor: pointer;
        }
        .date-range-text {
            margin-bottom: 10px;
            text-align: center;
        }

    </style>


</head>
<body>

    <div id="loadingOverlay" style="display:none; position: fixed; z-index: 9999; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.5);"></div>
    <div id="loadingModal" style="display:none; position: fixed; z-index: 10000; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 20px; text-align: center; background: #333; color: white; border-radius: 10px; width: 300px;">
        <div id="loadingSpinner" class="spinner-border text-light" role="status" style="margin-bottom: 15px;"></div>
        <div id="loadingMessage">Загрузка данных...</div>
        <div id="errorMessage" style="display:none; margin-bottom: 15px;"></div>
        <div id="loadingButtons" style="display:none;">
            <button id="retryButton" type="button" class="btn btn-primary" style="margin: 5px;">Повторить попытку</button>
            <button id="changeFilterButton" type="button" class="btn btn-secondary" style="margin: 5px;">Изменить фильтр</button>
        </div>
    </div>

    <div class="filter-panel">
        <!-- Форма для фильтрации -->
        <form id="filterForm">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="date_start">Начальная дата:</label>
                        <input type="date" name="date_start" class="form-control">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="date_end">Конечная дата:</label>
                        <input type="date" name="date_end" class="form-control">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col text-center">
                    <div class="separator">
                        <button type="button" class="btn btn-primary mt-2" onclick="applyFilter()">Применить фильтр</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div>
        <!-- вкладки -->
        <ul class="nav nav-pills flex-column flex-sm-row" id="Tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="general-tab" data-toggle="tab" data-target="#general" role="tab" aria-controls="general" aria-selected="true">
                    Общее
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="users-tab" data-toggle="tab" data-target="#users" role="tab" aria-controls="users" aria-selected="false">
                    Юзеры
                </a>
            </li>
        </ul>
        
        <div class="tab-content" id="TabContent">
            <!-- General -->
            <div class="date-range-text" id="date-range-text"></div>
            <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                <table class="table mt-4">
                    <thead>
                        <tr>
                            <th>Действие</th>
                            <th>Общее количество</th>
                            <!-- Динамические столбцы для UTM меток будут добавлены здесь -->
                        </tr>
                    </thead>
                    <tbody id="dataBodyGeneral">
                        <!-- Data will be dynamically populated here -->
                    </tbody>
                </table>
            </div>
            <!-- Users tab -->
            <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                <!-- Таблица для отображения результатов -->
                <div class="table-responsive-sm">
                    <table class="table table-sm mt-4">
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>id</th>
                                <th>Юзернейм</th>
                                <th>Регистрация</th>
                                <th>UTМ</th>
                                <th>Жал на кнопку реги<br>на консультацию</th>
                                <th>Заявка в АМО<br>(статус)</th>
                                <th>Жал на кнопку реги<br>на курс</th>
                                <th>Зарегался на курс</th>
                                <th>Написал в бота</th>
                                <th>Этап воронки бота</th>
                            </tr>
                        </thead>
                        <tbody id="dataBodyUsers">
                            <!-- Заполнится данными динамически -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </body>
    </html>
  `;



  return (
    <div dangerouslySetInnerHTML={{ __html: fullHtml }} />
  );
};


export default Index;